<template>
  <layout
    dark
    style="background: #f2f2f2;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div style="min-height: 96vh;" class="expande-horizontal wrap">
      <v-flex xs12 lg3>
        <Filtros :filter="filter" :class="{ 'pl-3': $vuetify.breakpoint.smAnd }" />
        <v-flex
          class="pa-3"
          v-if="getPersonalizedLoading === 'listando-insumos'"
          xs12
        >
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex class="px-3" v-else xs12>
          <v-list
            class="pa-0 ma-0"
            dense
            nav
            color="transparent"
            style="min-height: 20vh; overflow: auto;"
          >
            <v-list-item
              class="item-new"
              @click="iniciarCadastro"
              style="border-radius: 6px; margin-bottom: 6px;"
            >
              
              <v-icon size="21" class="mx-2" color="white">mdi-plus-circle-outline</v-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold fonte-subtitulo">
                  Novo Insumo
                </v-list-item-title>
                <span class="fonteMini white--text fonte">
                  Clique aqui e cadastre um novo insumo
                </span>
              </v-list-item-content>
            </v-list-item>
            <span class="mx-2 fonte fonteMini font-weight-bold black--text">Meus Insumos</span>
            <template v-for="(insumo, index) in get_insumos.docs">
              <v-list-item
                class="item-selected"
                @click="abririnsumo(insumo, index)"
                style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                :key="insumo._id"
              >
                <v-avatar
                  size="36"
                  :color="$theme.secondary"
                  class="mr-2 pa-0 a-0"
                >
                  <v-icon size="21" color="white">mdi-scale-balance</v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fonte-subtitulo">
                    {{ insumo.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonteMini font-weight-bold white--text">
                    {{ insumo.gerenciar_producao ? "Produção Local" : "" }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="fonteMini white--text">
                    {{ insumo.gerenciar_estoque ? "Estoque gerenciado" : "" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    class="animate__animated animate__fadeIn"
                    v-if="get_insumo._id === insumo._id"
                    icon
                  >
                    <v-icon color="white"> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex v-if="get_insumos.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-6 column centraliza"
            style="height: 36vh;"
          >
            <img style="width: 80%" src="img/empty.gif" alt="empty" />
            <span
              class="px-3 fonte-italica fonte-subtitulo grey--text text-center"
            >
              Não encontramos nenhum insumo, clique no botão abaixo e cadastre
              um!
            </span>
            <v-btn
              small
              rounded
              dark
              class=" mt-6 fonte-subtitulo fonte-italica"
              :color="$theme.secondary"
              @click="iniciarCadastro"
            >
              Novo insumo
              <v-icon
                size="12"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-arrow-projectile</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
      </v-flex>
      <!-- <v-flex xs0 md0 lg1></v-flex> -->
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
        <div
          v-if="get_insumo.new || get_insumo._id"
          class="expande-horizontal centraliza wrap"
        >
          <!-- Header de cadastro de insumos -->
          <v-flex xs12>
            <div class="windows-style mt-3 px-3 expande-horizontal fonte column">
              <transition name="slide-fade">
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-content>
                    <v-list-item-title class="fonte-bold">
                      {{ get_insumo.new ? "Novo insumo" : get_insumo.nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte fonteMini">
                      Gerencie os detalhes do insumo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="get_insumo._id">
                    <v-avatar @click="backToList" style="cursor: pointer;" size="27" :color="$theme.primary" class="mr-2" icon>
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <!-- <v-btn
                      @click="excluir_insumo_dialog(get_insumo)"
                      :color="$theme.primary"
                      x-small
                      class="fonte elevation-0 font-weight-bold text-captalize fonteMini white--text"
                      >Excluir</v-btn
                    > -->
                  </v-list-item-action>
                </v-list-item>
              </transition>
              <v-tabs
                v-model="tabs"
                class="fonte-bold"
                :color="$theme.primary"
                background-color="#f2f2f2"
                show-arrows
                dense
              >
                <v-tab>Detalhes</v-tab>
                <v-tab v-show="get_insumo.gerenciar_estoque">Estoque</v-tab>
                <v-tab v-show="get_insumo.gerenciar_producao"
                  >Ficha de produção</v-tab
                >
              </v-tabs>
              <v-divider class="mb-3"></v-divider>
            </div>
          </v-flex>
          <div class="windows-style-content pa-3 expande-horizontal wrap">
            <!-- Detalhes do insumos -->
            <v-flex xs12 v-show="tabs === 0">
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex class="pr-3" xs12 md5>
                      <v-text-field
                        label="Nome do insumo"
                        dense
                        filled
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: Alface"
                        v-model="get_insumo.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md5>
                      <v-text-field
                        label="Preço"
                        dense
                        filled
                        v-mask="['#.##', '##.##', '###.##', '####.##']"
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-if="!get_insumo.gerenciar_producao"
                        v-model="get_insumo.ultimo_preco_de_compra"
                      ></v-text-field>
                      <span class="fonte" v-else
                        >O preço deste insumo, será baseado na ficha técnica de
                        produção, acesse a aba Ficha de Produção acima</span
                      >
                    </v-flex>
                    <div class="expande-horizontal">
                      <!-- <v-flex class="pr-3" xs3 md1 :key="unidade.nome" v-for="unidade in unidades_de_medida_base"> -->
                      <v-flex class="pr-3" xs3 md4>
                        <!-- <div class="expande-horizontal centraliza column">
                          <v-avatar :class="{
                            'font-weight-bold elevation-1': unidade_selecionada(unidade) 
                          }" :color="``">
                            <span class="fonte fonteMini font-weight-bold white--text">
                              {{ unidade.valor }}
                            </span>
                          </v-avatar>
                        </div> -->
                        <v-select
                          label="Unidade do estoque mínimo"
                          dense
                          outlined
                          class="fonte-subtitulo fonte-italica"
                          placeholder="ex: KG"
                          type="Number"
                          :readonly="
                            get_insumo._id && !!get_insumo.unidade_estoque_minimo
                              ? true
                              : false
                          "
                          :items="unidades_de_medida_base"
                          item-text="nome"
                          return-object
                          :rules="[v => !!v || 'Campo obrigatório']"
                          v-model="get_insumo.unidade_estoque_minimo"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        v-if="get_insumo.gerenciar_estoque"
                        class="pr-3"
                        xs12
                        md4
                      >
                        <v-text-field
                          label="Estoque mínimo"
                          dense
                          outlined
                          class="fonte-subtitulo fonte-italica"
                          placeholder="ex: 12"
                          v-model="get_insumo.estoque_minimo"
                        ></v-text-field>
                      </v-flex>
                    </div>
                    <v-flex xs12 md4>
                      <div class="expande-horizontal column">
                        <v-switch
                          class="fonte fonteMini white--text"
                          :color="$theme.primary"
                          @change="createOrUpdate"
                          v-model="get_insumo.gerenciar_estoque"
                          label="Gerenciar estoque"
                        ></v-switch>
                        <v-switch
                          class="fonte fonteMini white--text"
                          :color="$theme.primary"
                          @change="createOrUpdate"
                          v-model="get_insumo.gerenciar_producao"
                          label="Gerenciar Produção do Insumo"
                        ></v-switch>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        class="fonte"
                        :color="$theme.secondary"
                        dark
                        @click="createOrUpdate"
                        >Salvar</v-btn
                      >
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- Gerência do estoque -->
            <v-flex
              v-show="
                get_insumo._id && get_insumo.gerenciar_estoque && tabs === 1
              "
              class="mb-3"
              xs12
            >
              <div class="expande-horizontal fonte column">
                <v-flex class="mb-3" xs12>
                  <transition name="slide-fade">
                    <v-list-item @click="iniciaCadastroEstoque" class="pa-0 ma-0">
                      <v-avatar :color="$theme.secondary" class="mr-2" icon>
                        <v-icon dark>mdi-cube-send</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ get_insumo.new ? "Novo insumo" : get_insumo.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-mini">
                          {{ quantidadeEstoque.quantidade
                          }}{{
                            get_insumo && get_insumo.unidade_estoque_minimo
                              ? get_insumo.unidade_estoque_minimo.valor
                              : ""
                          }}
                          em estoque
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :color="$theme.secondary"
                          class="text-capitalize"
                          @click="iniciaCadastroEstoque"
                          small
                        >
                          <v-icon color="white" class="mr-1" size="15"
                            >mdi-plus-circle-outline</v-icon
                          >
                          <span class="white--text fonte fonte-mini"
                            >Add Estoque</span
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition>
                </v-flex>
                <v-flex xs12>
                  <v-list three-line class="pa-0 ma-0">
                    <template v-for="estoque in get_estoques.docs">
                      <v-list-item
                        style="border-radius: 5px; background: #f2f2f2;"
                        class="ma-0 mb-2 pa-0"
                        :key="estoque._id"
                      >
                        <v-avatar
                          class="mx-3"
                          color="green"
                          v-if="estoque.quantidade > 0"
                        >
                          <v-icon size="18" color="white">
                            mdi-arrow-up-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-avatar class="mx-3" color="red" v-else>
                          <v-icon size="18" class="ml-2 mr-2" color="white">
                            mdi-arrow-down-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-list-item-content
                          class="fonte-subtitulo fonte-italica font-weight-bold"
                        >
                          <v-list-item-title>
                            {{ estoque.quantidade }}
                            {{
                              estoque && estoque.unidade_de_medida
                                ? estoque.unidade_de_medida.valor
                                : ""
                            }}
                            de
                            {{ estoque.produto.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Preço Base:
                            {{ $helper.formataSaldo(estoque.preco_de_compra) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Total comprado:
                            {{ sumValueBuyed(estoque) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="fonte-micro"
                            >{{
                              $moment(estoque.created_at).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="pr-3">
                          <v-btn
                            @click="beforeRemoveStock(estoque)"
                            small
                            dark
                            :color="$theme.secondary"
                            >Remover</v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
  
            <v-flex xs12 v-show="tabs === 2">
              <div class="expande-horizontal">
                <v-flex class="" xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-window v-model="windowInsumo">
                        <v-window-item :value="1">
                          <div
                            class="expande-horizontal pa-6 white--text column gradient-insumos"
                          >
                            <div class="centraliza pb-3 expande-horizontal">
                              <v-icon>mdi-list-box-outline</v-icon>
                              <h3 class="fonte ml-1">
                                Ficha Técnica de produção
                              </h3>
                              <v-spacer></v-spacer>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="expande-horizontal">
                              <h1 class="fonte">
                                {{
                                  $helper.formataSaldo(
                                    get_insumo.ultimo_preco_de_compra || 0
                                  )
                                }}
                              </h1>
                            </div>
                            <div class="expande-horizontal column">
                              <span class="fonte">
                                Este composto é composto por
                                {{
                                  get_insumo.ficha_tecnica
                                    ? get_insumo.ficha_tecnica.length
                                    : 0
                                }}
                                outros insumos, confira abaixo.
                              </span>
                              <div class="fonte mt-1 expande-horizontal">
                                <v-btn
                                  :color="$theme.secondary"
                                  dark
                                  class="font-weight-bold"
                                  @click="windowInsumo = 2"
                                >
                                  Adicionar insumo
                                  <v-icon right>
                                    mdi-chevron-right
                                  </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            class="expande-horizontal white--text column gradient-insumos-form"
                          >
                            <div
                              class="expande-horizontal centraliza px-3 py-6 wrap"
                            >
                              <div class="expande-horizontal">
                                <h3 class="fonte">
                                  Pesquise e selecione o insumo:
                                </h3>
                              </div>
                              <v-flex
                                xs12
                                v-if="
                                  insumos_ficha_tecnica_de_producao.docs.length >
                                    0 && insumos_ficha_tecnica_de_producao.docs
                                "
                              >
                                <v-list dark color="transparent" dense>
                                  <template
                                    v-for="(itemFicha,
                                    i) in insumos_ficha_tecnica_de_producao.docs"
                                  >
                                    <v-list-item
                                      class="item-list-insumo"
                                      @click="
                                        selecionaInsumoParaAdicionarAoProduto(
                                          itemFicha
                                        )
                                      "
                                      :key="i / 2"
                                    >
                                      <v-list-item-title>
                                        <span class="fonte">
                                          {{ itemFicha.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-action>
                                        <v-btn
                                          x-small
                                          text
                                          @click="
                                            selecionaInsumoParaAdicionarAoProduto(
                                              itemFicha
                                            )
                                          "
                                        >
                                          Adicionar
                                          <v-icon size="15" class="mr-2"
                                            >mdi-plus-circle</v-icon
                                          >
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-flex>
                              <div v-else>
                                <span class="fonte white--text text-center"
                                  >Sem resultados, faça uma busca abaixo</span
                                >
                              </div>
                            </div>
                            <v-card
                              light
                              flat
                              :color="$theme.primary"
                              class="expande-horizontal px-3 centraliza"
                            >
                              <v-flex class="pt-2 pr-2 pb-2">
                                <v-text-field
                                  v-model="filter_insumo_ficha_tecnica"
                                  append-icon="mdi-magnify"
                                  @click:append="
                                    list_insumos_ficha_tecnica_de_producao
                                  "
                                  @keyup.enter="
                                    list_insumos_ficha_tecnica_de_producao
                                  "
                                  hide-details
                                  solo
                                  flat
                                  dense
                                  placeholder="Pesquise o insumo aqui"
                                ></v-text-field>
                              </v-flex>
                              <!-- <Filtros style="color: #333" /> -->
                              <v-btn
                                color="red lighten-2"
                                class="fonte"
                                dark
                                small
                                @click="windowInsumo = 1"
                                >Cancelar</v-btn
                              >
                            </v-card>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3">
                          <div
                            class="expande-horizontal pt-3 pb-3 white--text column gradient-insumos-form"
                          >
                            <div class="expande-horizontal column px-3">
                              <div class="expande-horizontal">
                                <h3 class="fonte">
                                  Informe a porção a adicionar:
                                </h3>
                              </div>
                              <v-flex xs12>
                                <v-list dark color="transparent" dense>
                                  <v-list-item class="item-list-insumo">
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <span class="fonte">
                                          {{ windowInsumoForm.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span class="fonte">
                                          Unidade:
                                          {{
                                            windowInsumoForm
                                              .unidade_estoque_minimo.nome
                                          }}
                                        </span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-flex>
                              <v-flex xs12>
                                <v-form ref="formInsumo">
                                  <v-flex xs12>
                                    <v-text-field
                                      solo
                                      dense
                                      :rules="[v => !!v || 'Campo obrigatório']"
                                      ref="quantidadeField"
                                      v-mask="
                                        windowInsumoForm.unidade_estoque_minimo
                                          .nome === 'UNIDADE'
                                          ? ['#', '##', '###', '####']
                                          : ['#.##', '#.###', '##.##', '##.###']
                                      "
                                      @keyup.enter.prevent="
                                        finalizar_e_salvar_insumo
                                      "
                                      :prefix="
                                        `Quantidade em ${
                                          windowInsumoForm.unidade_estoque_minimo
                                            ? windowInsumoForm
                                                .unidade_estoque_minimo.nome
                                            : ''
                                        }: `
                                      "
                                      v-model="windowInsumoForm.quantidade"
                                    ></v-text-field>
                                  </v-flex>
                                </v-form>
                              </v-flex>
                            </div>
                            <div class="expande-horizontal centraliza">
                              <v-btn
                                x-small
                                dark
                                color="red lighten-2"
                                class="fonte mr-2"
                                @click="windowInsumo = 1"
                              >
                                <v-icon size="13" class="mr-1"
                                  >mdi-chevron-left</v-icon
                                >
                                Cancelar</v-btn
                              >
                              <v-btn
                                color="green"
                                dark
                                small
                                class="fonte"
                                @click="finalizar_e_salvar_insumo"
                                >Finalizar e adicionar
                                <v-icon>mdi-save</v-icon></v-btn
                              >
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                    <v-flex class="pl-1 pr-1" xs12>
                      <v-flex xs12>
                        <h5 class="fonte mt-3 grey--text">
                          Confira a ficha técnica abaixo:
                        </h5>
                      </v-flex>
                      <v-list
                        color="transparent"
                        dark
                        v-if="
                          get_insumo.ficha_tecnica &&
                            get_insumo.ficha_tecnica.length
                        "
                        dense
                      >
                        <template
                          v-for="(itemFicha, i) in get_insumo.ficha_tecnica"
                        >
                          <v-list-item
                            :style="
                              `border: 1px solid #e0e0e0; background: #f2f2f2; border-radius: 5px; margin-bottom: 6px;`
                            "
                            @click="() => {}"
                            :key="i / 2"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="fonte">
                                  {{ itemFicha.quantidade }}
                                  {{ itemFicha | filterUnidade }} de
                                  {{ itemFicha.nome }}
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="light-green--text fonte fonte-bold"
                              >
                                + {{ itemFicha | calcGramaTotal }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                icon
                                @click="removerInsumo(false, itemFicha, i)"
                              >
                                <v-icon color="red">mdi-minus</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                      <div v-else class="expande-horizontal centraliza">
                        <span class="fonte grey--text pa-6 text-center">
                          Este insumo ainda não possui ficha técnica de produção,
                          adicione acima.
                        </span>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_insumo._id || get_insumo.new ? true : false"
      >
        <v-card class="pl-3">
          <div
            v-if="get_insumo.new || get_insumo._id"
            class="expande-horizontal centraliza wrap"
          >
            <!-- Header de cadastro de insumos -->
            <v-flex class="mb-2" xs12>
              <div class="expande-horizontal fonte column">
                <transition name="slide-fade">
                  <v-list-item class="pa-0 pr-3 ma-0">
                    <v-avatar @click="backToList" style="cursor: pointer;" size="27" :color="$theme.primary" class="mr-2" icon>
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ get_insumo.new ? "Novo insumo" : get_insumo.nome }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-mini">
                        Gerencie os detalhes do insumo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="get_insumo._id">
                      <v-btn
                        color="red"
                        @click="excluir_insumo_dialog(get_insumo)"
                        outlined
                        small
                        >Excluir</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </transition>
                <v-tabs
                  v-model="tabs"
                  class="fonte"
                  :color="$theme.secondary"
                  background="#f2f2f2"
                  show-arrows
                >
                  <v-tab>Detalhes</v-tab>
                  <v-tab v-show="get_insumo.gerenciar_estoque">Estoque</v-tab>
                  <v-tab v-show="get_insumo.gerenciar_producao"
                    >Ficha de produção</v-tab
                  >
                </v-tabs>
                <v-divider class="mb-3"></v-divider>
              </div>
            </v-flex>
            <!-- Detalhes do insumos -->
            <v-flex class="pa-3" xs12 v-show="tabs === 0">
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex class="pr-3" xs12 md5>
                      <v-text-field
                        label="Nome do insumo"
                        dense
                        filled
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: Alface"
                        v-model="get_insumo.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3 pb-5" xs12 md5>
                      <v-text-field
                        label="Preço"
                        dense
                        filled
                        v-mask="['#.##', '##.##', '###.##', '####.##']"
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-if="!get_insumo.gerenciar_producao"
                        v-model="get_insumo.ultimo_preco_de_compra"
                      ></v-text-field>
                      <span class="fonte mb-6" v-else
                        >O preço deste insumo, será baseado na ficha técnica de
                        produção, acesse a aba Ficha de Produção acima</span
                      >
                    </v-flex>
                    <div class="expande-horizontal">
                      <v-flex class="pr-3" xs12 md4>
                        <v-select
                          label="Unidade do estoque mínimo"
                          dense
                          outlined
                          class="fonte-subtitulo fonte-italica"
                          placeholder="ex: KG"
                          type="Number"
                          :readonly="
                            get_insumo._id &&
                            !!get_insumo.unidade_estoque_minimo
                              ? true
                              : false
                          "
                          :items="unidades_de_medida_base"
                          item-text="nome"
                          return-object
                          :rules="[v => !!v || 'Campo obrigatório']"
                          v-model="get_insumo.unidade_estoque_minimo"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        v-if="get_insumo.gerenciar_estoque"
                        class="pr-3"
                        xs12
                        md4
                      >
                        <v-text-field
                          label="Estoque mínimo"
                          dense
                          outlined
                          class="fonte-subtitulo fonte-italica"
                          placeholder="ex: 12"
                          v-model="get_insumo.estoque_minimo"
                        ></v-text-field>
                      </v-flex>
                    </div>
                    <v-flex xs12 md4>
                      <div class="expande-horizontal column">
                        <v-switch
                          @change="createOrUpdate"
                          v-model="get_insumo.gerenciar_estoque"
                          label="Gerenciar estoque"
                        ></v-switch>
                        <v-switch
                          @change="createOrUpdate"
                          v-model="get_insumo.gerenciar_producao"
                          label="Gerenciar Produção do Insumo"
                        ></v-switch>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        class="fonte"
                        :color="$theme.secondary"
                        dark
                        @click="createOrUpdate"
                        >Salvar</v-btn
                      >
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- Gerência do estoque -->
            <v-flex
              v-show="
                get_insumo._id && get_insumo.gerenciar_estoque && tabs === 1
              "
              class="mb-3 pa-3"
              xs12
            >
              <div class="expande-horizontal fonte column">
                <v-flex class="mb-3" xs12>
                  <transition name="slide-fade">
                    <v-list-item
                      @click="iniciaCadastroEstoque"
                      class="pa-0 ma-0"
                    >
                      <v-avatar :color="$theme.secondary" class="mr-2" icon>
                        <v-icon dark>mdi-cube-send</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ get_insumo.new ? "Novo insumo" : get_insumo.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-mini">
                          {{ quantidadeEstoque.quantidade
                          }}{{
                            get_insumo && get_insumo.unidade_estoque_minimo
                              ? get_insumo.unidade_estoque_minimo.valor
                              : ""
                          }}
                          em estoque
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :color="$theme.secondary"
                          class="text-capitalize"
                          @click="iniciaCadastroEstoque"
                          x-small
                          rounded
                        >
                          <v-icon color="white" class="mr-1" size="15"
                            >mdi-plus-circle-outline</v-icon
                          >
                          <span class="white--text fonte fonteMini"
                            >Estoque</span
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition>
                </v-flex>
                <v-flex xs12>
                  <v-list three-line class="pa-0 ma-0">
                    <template v-for="estoque in get_estoques.docs">
                      <v-list-item
                        style="border-radius: 5px; background: #f2f2f2;"
                        class="ma-0 mb-2 pa-0"
                        :key="estoque._id"
                      >
                        <v-avatar
                          class="mx-3"
                          color="green"
                          v-if="estoque.quantidade > 0"
                        >
                          <v-icon size="18" color="white">
                            mdi-arrow-up-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-avatar class="mx-3" color="red" v-else>
                          <v-icon size="18" class="ml-2 mr-2" color="white">
                            mdi-arrow-down-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-list-item-content
                          class="fonte-subtitulo fonte-italica font-weight-bold"
                        >
                          <v-list-item-title>
                            {{ estoque.quantidade }}
                            {{
                              estoque && estoque.unidade_de_medida
                                ? estoque.unidade_de_medida.valor
                                : ""
                            }}
                            de
                            {{ estoque.produto.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Preço Base:
                            {{ $helper.formataSaldo(estoque.preco_de_compra) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Total comprado:
                            {{ sumValueBuyed(estoque) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="fonte-micro"
                            >{{
                              $moment(estoque.created_at).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="pr-3">
                          <v-btn
                            class="fonte font-weight-bold elevation-0"
                            rounded
                            @click="beforeRemoveStock(estoque)"
                            x-small
                            dark
                            :color="$theme.secondary"
                            >Remover</v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>

            <v-flex xs12 class="pa-3" v-show="tabs === 2">
              <div class="expande-horizontal">
                <v-flex class="" xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-window v-model="windowInsumo">
                        <v-window-item :value="1">
                          <div
                            class="expande-horizontal pa-6 white--text column gradient-insumos"
                          >
                            <div class="centraliza pb-3 expande-horizontal">
                              <v-icon>mdi-list-box-outline</v-icon>
                              <h3 class="fonte ml-1">
                                Ficha Técnica de produção
                              </h3>
                              <v-spacer></v-spacer>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="expande-horizontal">
                              <h1 class="fonte">
                                {{
                                  $helper.formataSaldo(
                                    get_insumo.ultimo_preco_de_compra || 0
                                  )
                                }}
                              </h1>
                            </div>
                            <div class="expande-horizontal column">
                              <span class="fonte">
                                Este composto é composto por
                                {{
                                  get_insumo.ficha_tecnica
                                    ? get_insumo.ficha_tecnica.length
                                    : 0
                                }}
                                outros insumos, confira abaixo.
                              </span>
                              <div class="pb-3 fonte mt-1 expande-horizontal">
                                <v-btn
                                  :color="$theme.secondary"
                                  dark
                                  class="font-weight-bold"
                                  @click="windowInsumo = 2"
                                >
                                  Adicionar insumo
                                  <v-icon right>
                                    mdi-chevron-right
                                  </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            class="expande-horizontal white--text column gradient-insumos-form"
                          >
                            <div
                              class="expande-horizontal centraliza px-3 py-6 wrap"
                            >
                              <div class="expande-horizontal">
                                <h3 class="fonte">
                                  Pesquise e selecione o insumo:
                                </h3>
                              </div>
                              <v-flex
                                xs12
                                v-if="
                                  insumos_ficha_tecnica_de_producao.docs.length > 0 &&
                                  insumos_ficha_tecnica_de_producao.docs
                                "
                              >
                                <v-list dark color="transparent" dense>
                                  <template
                                    v-for="(itemFicha, i) in insumos_ficha_tecnica_de_producao.docs"
                                  >
                                    <v-list-item
                                      class="item-list-insumo"
                                      @click="
                                        selecionaInsumoParaAdicionarAoProduto(
                                          itemFicha
                                        )
                                      "
                                      :key="i / 2"
                                    >
                                      <v-list-item-title>
                                        <span class="fonte">
                                          {{ itemFicha.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-action>
                                        <v-btn
                                          x-small
                                          text
                                          @click="
                                            selecionaInsumoParaAdicionarAoProduto(
                                              itemFicha
                                            )
                                          "
                                        >
                                          Adicionar
                                          <v-icon size="15" class="mr-2"
                                            >mdi-plus-circle</v-icon
                                          >
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-flex>
                              <div v-else>
                                <span class="fonte white--text text-center"
                                  >Sem resultados, faça uma busca abaixo</span
                                >
                              </div>
                            </div>
                            <v-card
                              light
                              flat
                              :color="$theme.primary"
                              class="expande-horizontal px-3 centraliza"
                            >
                              <Filtros style="color: #333" />
                              <v-btn
                                color="red lighten-2"
                                class="fonte"
                                dark
                                small
                                @click="windowInsumo = 1"
                                >Cancelar</v-btn
                              >
                            </v-card>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3">
                          <div
                            class="expande-horizontal pt-3 pb-3 white--text column gradient-insumos-form"
                          >
                            <div class="expande-horizontal column px-3">
                              <div class="expande-horizontal">
                                <h3 class="fonte">
                                  Informe a porção a adicionar:
                                </h3>
                              </div>
                              <v-flex xs12>
                                <v-list dark color="transparent" dense>
                                  <v-list-item class="item-list-insumo">
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <span class="fonte">
                                          {{ windowInsumoForm.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span class="fonte">
                                          Unidade:
                                          {{
                                            windowInsumoForm
                                              .unidade_estoque_minimo.nome
                                          }}
                                        </span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-flex>
                              <v-flex xs12>
                                <v-form ref="formInsumo">
                                  <v-flex xs12>
                                    <v-text-field
                                      solo
                                      dense
                                      :rules="[v => !!v || 'Campo obrigatório']"
                                      ref="quantidadeField"
                                      v-mask="
                                      windowInsumoForm.unidade_estoque_minimo
                                        .nome === 'UNIDADE'
                                        ? ['#', '##', '###', '####']
                                        : ['#.##', '#.###', '##.##', '##.###']
                                      "
                                      @keyup.enter.prevent="
                                        finalizar_e_salvar_insumo
                                      "
                                      :prefix="
                                        `Quantidade em ${
                                          windowInsumoForm.unidade_estoque_minimo
                                            ? windowInsumoForm
                                                .unidade_estoque_minimo.nome
                                            : ''
                                        }: `
                                      "
                                      v-model="windowInsumoForm.quantidade"
                                    ></v-text-field>
                                  </v-flex>
                                </v-form>
                              </v-flex>
                            </div>
                            <div class="expande-horizontal centraliza">
                              <v-btn
                                x-small
                                dark
                                color="red lighten-2"
                                class="fonte mr-2"
                                @click="windowInsumo = 1"
                              >
                                <v-icon size="13" class="mr-1"
                                  >mdi-chevron-left</v-icon
                                >
                                Cancelar</v-btn
                              >
                              <v-btn
                                color="green"
                                dark
                                small
                                class="fonte"
                                @click="finalizar_e_salvar_insumo"
                                >Finalizar e adicionar
                                <v-icon>mdi-save</v-icon></v-btn
                              >
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                    <v-flex class="pl-1 pr-1" xs12>
                      <v-flex xs12>
                        <h5 class="fonte white--text">
                          Confira a ficha técnica abaixo:
                        </h5>
                      </v-flex>
                      <v-list
                        color="transparent"
                        dark
                        v-if="
                          get_insumo.ficha_tecnica &&
                            get_insumo.ficha_tecnica.length
                        "
                        dense
                      >
                        <template
                          v-for="(itemFicha, i) in get_insumo.ficha_tecnica"
                        >
                          <v-list-item
                            :style="
                              `border: 1px solid #e0e0e0; background: #f2f2f2; border-radius: 5px; margin-bottom: 6px;`
                            "
                            @click="() => {}"
                            :key="i / 2"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="fonte">
                                  {{ itemFicha.quantidade }}
                                  {{ itemFicha | filterUnidade }} de
                                  {{ itemFicha.nome }}
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="light-green--text fonte fonte-bold"
                              >
                                + {{ itemFicha | calcGramaTotal }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                icon
                                @click="removerInsumo(false, itemFicha, i)"
                              >
                                <v-icon color="red">mdi-minus</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                      <div v-else class="expande-horizontal centraliza">
                        <span class="fonte grey--text pa-6 text-center">
                          Este insumo ainda não possui ficha técnica de
                          produção, adicione acima.
                        </span>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      fullscreen
      persistent
      v-model="formEstoqueDialog"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
    >
      <div
        class="expande-horizontal px-3 centraliza"
        style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
      >
        <v-card width="500" class="expande-horizontal wrap">
          <div class="expande-horizontal px-1 pr-3 py-3 pb-0">
            <v-btn dark @click="formEstoqueDialog = false" icon>
              <v-icon color="orange">
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <span
              style="font-size: 17pt; width: 100%;"
              class="fonte-subtitulo font-weight-bold fonte-italica"
            >
              + {{ get_insumo.nome }}
            </span>
            <v-btn icon @click="formEstoqueDialog = false">
              <v-icon color="orange">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="expande-horizontal pa-3 wrap">
            <v-flex xs12>
              <div class="fonte expande-horizontal centraliza">
                <h1 class="fonte-big">
                  {{ get_estoque.quantidade }}
                </h1>
                <span class="pt-2" v-if="get_estoque.unidade_de_medida">{{
                  get_estoque.unidade_de_medida.valor
                }}</span>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-form ref="formEstoque">
                <div class="expande-horizontal wrap">
                  <v-flex class="pa-1" xs12>
                    <span class="fonte-italica fonte-subtitulo">
                      Quantidade
                    </span>
                    <v-text-field
                      solo
                      flat
                      autofocus
                      ref="qtdEstoque"
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: 100"
                      type="Number"
                      hide-details
                      :rules="[
                        v => !!v || 'Campo obrigatório',
                        v => v > 0 || 'Valor deve ser maior que zero'
                      ]"
                      v-model="get_estoque.quantidade"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="
                      get_estoque.unidade_de_medida &&
                        get_estoque.unidade_de_medida.nome === 'CAIXA'
                    "
                    class="pa-1 pt-0"
                    xs12
                  >
                    <span class="fonte-italica fonte-subtitulo">
                      Quantidade de itens por caixa
                    </span>
                    <v-text-field
                      solo
                      flat
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: 10"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      type="Number"
                      v-model="get_estoque.unidade_de_medida.divisor"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pa-1 pt-0" xs12>
                    <span class="fonte-italica fonte-subtitulo">
                      Preço
                      <span
                        class="text-lowercase"
                        v-if="get_estoque.unidade_de_medida"
                        >por {{ get_estoque.unidade_de_medida.nome }}</span
                      >
                    </span>
                    <v-text-field
                      solo
                      flat
                      prefix="R$"
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: 10.50"
                      @keyup.enter.prevent="adicionarEstoque"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      v-mask="['#,##', '##,##', '###,##', '####,##']"
                      v-model="get_estoque.preco_de_compra"
                    ></v-text-field>
                  </v-flex>
                </div>
              </v-form>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal">
                <v-btn
                  @click="adicionarEstoque"
                  color="green"
                  dark
                  class="fonte-subtitulo fonte-italica"
                  >Adicionar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import ModalSendArchiveById from "../../shared/components/ModalSendById.vue";
export default {
  data() {
    return {
      windowInsumo: 0,
      windowInsumoForm: {
        unidade_estoque_minimo: {}
      },
      card: false,
      contextDialog: false,
      drawer: false,
      formEstoqueDialog: false,
      tabs: 0,
      options: [
        {
          nome: "Novo insumo",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
        // {
        //   nome: "Estoque",
        //   action: this.iniciaCadastroEstoque,
        //   icon: "mdi-plus"
        // }
      ],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Meus insumos",
          path: "/insumos"
        }
      ],
      get_categoria: {
        insumos: []
      },
      nova_secao: "",
      formPrecoMultiplo: {},
      unidades_de_medida: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "GRAMA(g)",
          valor: "g",
          divisor: 1
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "Mililitro (ML)",
          valor: "ML",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        {
          nome: "CAIXA",
          valor: "CX",
          divisor: 1
        },
        {
          nome: "PACOTE",
          valor: "PCT",
          divisor: 1
        }
      ],
      unidades_de_medida_base: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        }
        // {
        //   nome: "CAIXA",
        //   valor: "CX"
        // },
        // {
        //   nome: "PACOTE",
        //   valor: "PCT",
        //   divisor: 1
        // }
      ],
      insumos_ficha_tecnica_de_producao: {
        docs: []
      },
      filter: {},
      loading: ""
    };
  },
  filters: {
    precoDeEntrada(val) {
      const quantidade = parseFloat(val.quantidade);
      const preco_de_compra = parseFloat(val.preco_de_compra);
      const preco_por_divisor = preco_de_compra / val.divisor;
      const total_de_entrada = quantidade * preco_por_divisor;
      return total_de_entrada;
    },
    calcGramaTotal(val) {
      const quantidadeStringed = `${val.quantidade}`;
      const quantidade = parseInt(quantidadeStringed.replace(".", ""));
      const preco_de_compra = parseFloat(val.ultimo_preco_de_compra);
      const preco_do_divisor =
        preco_de_compra / val.unidade_estoque_minimo.divisor;
      const total_de_entrada = quantidade * preco_do_divisor;
      return total_de_entrada.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    },
    filterUnidade(value) {
      if (
        value.unidade_estoque_minimo.valor == "un" ||
        value.unidade_estoque_minimo.valor == "und" ||
        value.unidade_estoque_minimo.valor == "UND" ||
        value.unidade_estoque_minimo.valor == "UN"
      ) {
        return "unidade(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "kg" ||
        value.unidade_estoque_minimo.valor == "KG"
      ) {
        return "quilo(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "g" ||
        value.unidade_estoque_minimo.valor == "GRAMA"
      ) {
        return "grama(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "l" ||
        value.unidade_estoque_minimo.valor == "L"
      ) {
        return "litro(s)";
      } else if (value.unidade_estoque_minimo.valor == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_estoque_minimo.valor;
      }
    }
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView,
    ModalSendArchive,
    ModalSendArchiveById
  },
  computed: {
    ...mapGetters([
      "get_tenants",
      "get_estoque",
      "get_estoques",
      "get_tenants_filtros",
      "get_insumos_filtros",
      "get_estoques_filtros",
      "get_insumocategorias",
      "get_insumocategoria",
      "getLoggedUser",
      "get_insumo",
      "get_insumos",
      "getLinksById",
      "getPersonalizedLoading"
    ]),
    quantidadeEstoque() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(
          val.preco_de_compra ? val.preco_de_compra : 0
        );
        const preco_por_divisor =
          preco_de_compra / (val.divisor ? val.divisor : 0);
        const total_de_entrada = quantidade * (preco_por_divisor || 0);
        return total_de_entrada;
      };
      let totalEstoque = {
        quantidade: 0,
        valor_total: 0
      };
      this.get_estoques.docs.map(estoque => {
        (totalEstoque.quantidade += estoque.quantidade),
          (totalEstoque.valor_total += calcPreco(estoque));
      });
      totalEstoque.quantidade = totalEstoque.quantidade.toFixed(2);

      return totalEstoque;
    }
  },
  methods: {
    seleciona_unidade() {

    },
    unidade_selecionada(unidade) {
      if (!this.get_insumo.nome) {
        return false
      }
      if (this.get_insumo.unidade_de_medida) {
        return false;
      }
      if (this.get_insumo.unidade_de_medida.nome === unidade.nome) {
        return true;
      }
    },
    list_insumos_ficha_tecnica_de_producao() {
      this.loading = "insumos_ficha_tecnica";
      this.$run("insumos/list", {
        nome: this.filter_insumo_ficha_tecnica
      }).then(res => {
        this.loading = "";
        this.insumos_ficha_tecnica_de_producao = res.data;
      });
    },
    excluir_insumo_dialog(item) {
      let text = "Deseja remover este insumo?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_insumo",
        action_value: item
      });
    },
    excluir_estoque_dialog(item) {
      let text = "Deseja remover registro do estoque?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_estoque",
        action_value: item
      });
    },
    backToList() {
      this.$store.commit("set_insumo", {});
      this.$forceUpdate();
    },
    sumValueBuyed(val) {
      let qtd = val.quantidade || 0;
      let priceBuy = val.preco_de_compra || 0;
      let sum = qtd * priceBuy;
      return this.$helper.formataSaldo(sum);
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    iniciaCadastroEstoque() {
      this.formEstoqueDialog = true;
      this.get_estoque.unidade_de_medida = this.get_insumo.unidade_estoque_minimo;
      // this.$refs.qtdEstoque.focus();
    },
    abririnsumo(insumo) {
      this.$store.commit("set_insumo", insumo);
      this.$store.commit("setLinks", insumo.imagens);
      this.get_estoques_filtros.produto = insumo;
      this.listar_estoques();
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_insumo.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_insumo.preco_multiplo = novoPrecoMultiplo;
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    closeContextDialog() {
      this.contextDialog = false;
      setTimeout(() => {
        this.drawer = true;
      }, 10);
    },
    poc(text) {
      console.log(text);
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_insumos",
      "criar_estoque",
      "listar_insumocategorias",
      "criar_insumo",
      "listar_estoques",
      "atualizar_insumo",
      "setPersonalizedLoading",
      "createConfirmAction",
      "createGlobalMessage",
      "excluir_estoque"
    ]),
    adicionarNovoPreco() {
      if (this.$refs.formNewPrecoMultiplo.validate()) {
        const preco = this.formPrecoMultiplo;
        this.get_insumo.preco_multiplo.push(preco);
        this.formPrecoMultiplo = {};
      }
    },
    criarOuAtualizar() {
      if (this.get_insumo._id) {
        this.atualizar_insumo();
      } else {
        this.criar_insumo();
      }
    },
    adicionarEstoque() {
      if (this.$refs.formEstoque.validate()) {
        this.get_estoque.produto = this.get_insumo;
        this.get_estoque.origem = "insumo";
        this.criar_estoque();
        this.formEstoqueDialog = false;
      }
    },
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_insumo", {
        new: true,
        preco_multiplo: []
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
      this.$store.commit("set_estoques", {
        docs: [],
        page: 1,
        totalPages: 1
      });
      this.tabs = 0;
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_insumo._id) {
          this.atualizar_insumo();
        } else {
          this.criar_insumo();
        }
      }
    },
    selecionaInsumoParaAdicionarAoProduto(item) {
      this.windowInsumoForm = item;
      this.windowInsumo = 3;
      setTimeout(() => {
        this.$refs.quantidadeField.focus();
      }, 200);
    },
    finalizar_e_salvar_insumo() {
      let quantidade;
      if (this.windowInsumoForm.unidade_estoque_minimo.divisor > 1) {
        quantidade = parseFloat(this.windowInsumoForm.quantidade).toFixed(3);
      } else if (this.windowInsumoForm.unidade_estoque_minimo.divisor === 1) {
        quantidade = parseInt(this.windowInsumoForm.quantidade);
      }
      if (this.$refs.formInsumo.validate()) {
        if (!this.get_insumo.ficha_tecnica) {
          this.get_insumo.ficha_tecnica = [];
        }
        this.get_insumo.ficha_tecnica.push({
          ...this.windowInsumoForm,
          quantidade
        });
        this.windowInsumo = 1;
        this.calcPrecos();
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Adicionamos ${
            this.windowInsumoForm.quantidade
          } ${this.calcUnidadeDeMedida(this.windowInsumoForm)} de ${
            this.windowInsumoForm.unidade_estoque_minimo.nome
          } ao insumo ${this.get_insumo.nome} com sucesso!`,
          type: "success"
        });
      }
    },
    calcUnidadeDeMedida(value) {
      if (
        value.unidade_estoque_minimo.valor == "und" ||
        value.unidade_estoque_minimo.valor == "UND"
      ) {
        return "unidade(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "kg" ||
        value.unidade_estoque_minimo.valor == "KG"
      ) {
        return "quilo(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "g" ||
        value.unidade_estoque_minimo.valor == "GRAMA"
      ) {
        return "grama(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "l" ||
        value.unidade_estoque_minimo.valor == "L"
      ) {
        return "litro(s)";
      } else if (value.unidade_estoque_minimo.valor == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_estoque_minimo.valor;
      }
    },
    removerInsumo(confirmation, item, index) {
      if (confirmation) {
        this.get_insumo.ficha_tecnica.splice(index, 1);
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Removemos ${item.quantidade} ${this.calcUnidadeDeMedida(
            item
          )} de ${item.nome} do produto ${this.get_insumo.nome} com sucesso!`,
          type: "success"
        });
        this.calcPrecos();
      } else {
        this.createConfirmAction({
          message: `Deseja remover ${
            item.quantidade
          } ${this.calcUnidadeDeMedida(item)} de ${item.nome}?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerInsumo(true, item, index),
          action_value: "ok"
        });
      }
    },
    beforeRemoveStock(item) {
      this.createConfirmAction({
        message: `Isso também removerá o valor do extrato, deseja continuar?`,
        icone: "mdi-warning",
        action: "excluir_estoque",
        action_value: item
      });
    },
    calcPrecos() {
      const calcPreco = val => {
        const quantidadeStringed = `${val.quantidade}`;
        const quantidadeConverted = parseInt(
          quantidadeStringed.replace(".", "")
        );
        console.log("val", val);
        console.log("qtd string", quantidadeStringed);
        console.log("qtd string conv", quantidadeConverted);
        const preco_de_compra = val.ultimo_preco_de_compra;
        console.log("preco de compra", preco_de_compra);
        const preco_do_divisor =
          preco_de_compra / val.unidade_estoque_minimo.divisor;
        console.log("preco do divisor", preco_do_divisor);
        const total_de_entrada = quantidadeConverted * preco_do_divisor;
        console.log("total de entrada", total_de_entrada);
        return total_de_entrada;
      };
      let total = 0;
      const ficha_tecnica = this.get_insumo.ficha_tecnica || [];
      ficha_tecnica.map(estoque => {
        total += calcPreco(estoque);
      });

      // const preco_com_lp =
      //   total + (total / 100) * this.get_insumo.lucro_pretendido;
      // const preco_com_markup =
      this.get_insumo.ultimo_preco_de_compra = parseFloat(total.toFixed(2));
      // this.get_insumo.preco_com_lp = parseFloat(preco_com_lp.toFixed(2));
      // this.get_insumo.preco_com_markup = this.calcMarkup(
      //   parseFloat(total.toFixed(2))
      // );
      this.createOrUpdate();
    }
  },
  created() {
    this.get_insumos_filtros.limitPerPage = 7;
    this.listar_insumos();
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #673AB7;
}
.item-selected {
  background: #673AB7 !important;
  color: #fff !important;
}
.item-new {
  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f2994a,
    #f2c94c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f2994a, #f2c94c);
  color: #fff !important;
}
.gradient-insumos {
  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f2994a,
    #f2c94c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f2994a, #f2c94c);
  border-radius: 5px;
  height: 220px;
  justify-content: center;
  align-items: center;
}
.gradient-insumos-form {
  background: -webkit-linear-gradient(
    to right,
    #f2994a,
    #f2c94c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f2994a, #f2c94c);
  border-radius: 5px;
  min-height: 220px;
  justify-content: center;
  align-items: center;
}

.windows-style {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f2f2f2;
}
.windows-style-content {
  border-bottom: 1px solid #e0e0e0;
  background: #f2f2f2;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
</style>
